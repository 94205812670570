<template>
  <div>
    <b-row class="pl-3 pr-3 pb-4 pt-2">
      <b-col
        sm="4"
        v-for="(item, index) in patients2"
        :key="index"
        class="mb-3"
      >
        <b-card no-body class="card_bed">
          <div
            class="header_bed_card d-flex justify-content-between align-items-center"
          >
            <div class="" v-if="item.patient">
              {{
                item.patient.first_name +
                " " +
                item.patient.last_name
              }}
            </div>
            <div class="d-flex align-items-center" v-if="item.location_patient">
              <div
                v-for="risk in item.location_patient.risks.slice(0, 3)"
                :key="risk.id"
                v-b-tooltip.hover.top="risk.name"
                class="bar_risk"
                :style="'background-color: ' + risk.color"
              >
                {{ risk.name }}
              </div>
            </div>
            <div>
              <img
                src="@/assets/images/icontemperatura.png"
                class="icon_temperature_bed"
              />
              <span class="title_icon">
                {{ item.device ? item.device : "-" }}°C
              </span>
            </div>
          </div>
          <div class="padding_">
            <div class="d-flex justify-content-between">
              <div class="d-flex flex-column">
                <div class="title_header mr-3">{{ item.name }}</div>
                <div
                  v-if="item.isVisible"
                  class="d-flex align-items-center justify-content-center"
                >
                  <img
                    style="width: 60px"
                    src="@/assets/images/flechaizquierda.gif"
                    alt="helpmedica"
                  />
                </div>
                <!-- <div
                  v-if="item.isVisible"
                  class="d-flex align-items-center justify-content-center"
                >
                  <b-icon-arrow-left-square-fill class="mr-1 icon_arrow" />
                  <b-spinner
                    type="grow"
                    :variant="item.variant"
                    class="mr-1"
                  ></b-spinner>
                  <b-spinner type="grow" :variant="item.variant"></b-spinner>
                </div> -->
              </div>
              <div>
                <div class="title_body">Ronda</div>
                <div
                  class="d-flex justify-content-lg-center align-items-center"
                >
                  <div class="round_bed" />
                </div>
              </div>
              <div>
                <div class="title_body">Alimento</div>
                <div v-if="item.patient" class="text-center">
                  <div v-if="isBusy" class="text-center text-primary">
                    <b-spinner class="align-middle" />
                  </div>
                  <div
                    v-else-if="isError"
                    class="btn_reload"
                    @click.prevent="$emit('reloadDiets')"
                  >
                    <b-icon-arrow-clockwise />
                  </div>
                  <div v-else-if="isEmpty">
                    <b-badge variant="danger"> Sin asignar </b-badge>
                  </div>
                  <div v-else>
                    <div
                      v-if="item.orders.length > 0"
                      class="d-flex justify-content-center flex-column"
                    >
                      <div
                        v-for="(option, index) in item.orders"
                        :key="index"
                        :class="colorDiet(option.type, option.status)"
                        class="box_food"
                      >
                        {{ textDiet(option.type) }}
                      </div>
                    </div>
                    <div v-else>
                      <b-badge variant="danger"> Sin asignar </b-badge>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div class="title_body">Exámen</div>
                <div class="text-center">
                  <b-progress :max="100" animated variant="info">
                    <b-progress-bar
                      :value="
                        (item.exams.length / item.examsTotal.length) * 100
                      "
                    >
                      <strong>{{
                        (item.exams.length / item.examsTotal.length) * 100
                      }}</strong>
                    </b-progress-bar>
                  </b-progress>
                  <span class=""
                    ><strong
                      >{{ item.exams.length }} /
                      {{ item.examsTotal.length }}</strong
                    ></span
                  >
                </div>
              </div>
              <!-- <div>
                <div class="title_body">Medicamento</div>
                <div class="text-center">
                  <apexchart
                    id="radiar_bar_bed"
                    :options="medicines"
                    width="135"
                    :series="series"
                  />
                  <span class="title_radiar2">12/14</span>
                </div>
              </div> -->
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  VBTooltip,
  BBadge,
  // BIconArrowLeftSquareFill,
  BSpinner,
  BProgress,
  BProgressBar,
} from "bootstrap-vue";

export default {
  components: {
    BCard,
    BCol,
    BRow,
    BBadge,
    // BIconArrowLeftSquareFill,
    BSpinner,
    BProgress,
    BProgressBar,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  props: {
    patients: {
      type: Array,
      default: null,
    },
    isBusy: {
      type: Boolean,
      default: false,
    },
    isBusy2: {
      type: Boolean,
      default: false,
    },
    isError: {
      type: Boolean,
      default: false,
    },
    isEmpty: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      exams: {
        chart: {
          type: "radialBar",
        },
        plotOptions: {
          radialBar: {
            hollow: {
              size: "40%",
            },
            track: {
              show: true,
              background: "#d2d2d2",
            },
            dataLabels: {
              show: false,
            },
          },
        },
        labels: [""],
        colors: ["#28c76f"],
      },
      medicines: {
        chart: {
          type: "radialBar",
        },
        plotOptions: {
          radialBar: {
            hollow: {
              size: "40%",
            },
            track: {
              show: true,
              background: "#d2d2d2",
            },
            dataLabels: {
              show: false,
            },
          },
        },
        labels: [""],
        colors: ["#35b0db"],
      },
      series: ["60"],
      patients2: [],
      copyPatients: [],
      page: 1,
      perPage: 15,
      perPage2: 15,
      totalRows: 0,
      totalPages: 0,
      initial: 0,
      interval: null,
    };
  },
  watch: {
    patients() {
      const me = this;
      me.loadData();
    },
  },
  methods: {
    colorDiet(type, status) {
      let color = "";

      if (type == "1" && status) {
        color = "breakfast";
      } else if (type == "2" && status) {
        color = "luch";
      } else if (type == "3" && status) {
        color = "dinner";
      }
      return color;
    },
    textDiet(type) {
      let text = "";

      if (type == "1") {
        text = "Desayuno";
      } else if (type == "2") {
        text = "Almuerzo";
      } else {
        text = "Cena";
      }
      return text;
    },
    sliderPatients() {
      const me = this;
      me.totalRows = me.patients.length;
      me.totalPages = Math.ceil(me.totalRows / me.perPage);
      if (me.totalPages > 1) {
        me.interval = setInterval(() => {
          if (me.page < me.totalPages) {
            me.page += 1;
            me.perPage += me.perPage2;
            me.initial += me.perPage2;
            me.patients2 = me.copyPatients.slice(me.initial, me.perPage);
          } else {
            me.page = 1;
            me.perPage = me.perPage2;
            me.initial = 0;
            me.copyPatients = me.patients;
            me.patients2 = me.copyPatients.slice(me.initial, me.perPage);
          }
        }, 5000);
      }
    },
    loadData() {
      const me = this;
      if (me.patients.length > 0) {
        me.patients2 = me.patients?.slice(me.initial, me.perPage);
        me.copyPatients = me.patients;
        me.sliderPatients();
      }
    },
  },
  mounted() {
    const me = this;
    me.loadData();
  },
  destroyed() {
    clearInterval(this.interval);
  },
};
</script>

<style lang="css">
.card_bed {
  box-shadow: 0 5px 11px 0 rgba(145, 144, 144, 0.3),
    0 4px 15px 0 rgba(133, 132, 132, 0.15);
  border-radius: 0.5rem;
}

.header_bed_card {
  background-color: #E6E6E6;
  padding: 10px;
  height: 50px;
  color: #5b5b5b;
  font-family: var(--fuente-montserrat);
  border-radius: 0rem;
}

.title_header {
  font-size: 18px;
  color: #5b5b5b;
  font-family: var(--fuente-montserrat);
}

.title_icon {
  font-size: 18px;
  color: #5b5b5b;
  font-family: var(--fuente-montserrat);
}

.icon_temperature_bed {
  position: relative;
  left: 2px;
  height: 25px;
}

.padding_ {
  padding: 8px;
}

.bar_rish_riesk2 {
  padding: 2.5px;
  border-radius: 0.3rem;
  background-color: #7367f0;
  margin-right: 5px;
  width: 25px;
}

.title_body {
  font-size: 16px;
  color: #5b5b5b;
  font-family: var(--fuente-montserrat);
  margin-bottom: 5px;
  text-align: center;
}

.bar_rish_riesk {
  padding: 3.5px;
  border-radius: 0.3rem;
  background-color: #7367f0;
  margin-right: 10px;
  width: 40px;
}

.round_bed {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #50B05E;
  position: relative;
  top: 3px;
}

#radiar_bar_bed {
  display: flex;
  justify-content: center;
  position: relative;
  top: -15px;
  margin-bottom: -60px;
  margin-right: -40px;
  margin-left: -40px;
}

.title_radiar1 {
  position: relative;
  top: -16px !important;
  color: #28c76f;
  font-weight: 700;
  font-size: 16px;
}

.title_radiar2 {
  position: relative;
  top: -16px !important;
  color: #35b0db;
  font-weight: 700;
  font-size: 16px;
}

.box_food {
  background: #b2b2b2;
  width: 100%;
  height: 15px;
  margin-bottom: 2px;
  font-size: 10px;
  color: white;
  border-radius: 0.2rem;
  text-align: center;
}

.box_food.breakfast {
  background-color: #28c76f;
  color: white;
}

.box_food.luch {
  background-color: #28c76f;
  color: white;
}

.box_food.dinner {
  background-color: #28c76f;
  color: white;
}

.bar_risk {
  padding: 0.5px 2px 0.5px 2px;
  border-radius: 0.2rem;
  background-color: #7367f0;
  margin-right: 10px;
  width: 60px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  font-size: 11px;
  color: white;
  text-align: center;
  margin-bottom: 0px;
}
</style>

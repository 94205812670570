<template>
  <div>
    <b-card
      no-body
      id="card_first2"
      class="card_first2 p-2"
      :class="shadowColor(calledOne.called)"
    >
      <div class="header_first2 d-flex justify-content-between">
        <div class="" v-if="!isIncendio && !isSecondOption">
          <div class="name_header2 mb-0">
            {{
              calledOne.patient.patient.first_name +
              " " +
              calledOne.patient.patient.last_name
            }}
          </div>
          <div class="title_riesk_first2 text-left">Riesgos</div>
          <div class="d-flex">
            <div
              v-for="risk in calledOne.patient.location_patient.risks.slice(
                0,
                3
              )"
              :key="risk.id"
              v-b-tooltip.hover.top="risk.name"
              class="bar_rish_riesk_first2"
              :style="'background-color: ' + risk.color"
            />
          </div>
        </div>
        <div class="d-flex justify-content-end">
          <img
            src="@/assets/images/icontemperatura.png"
            class="icon_temperature2"
          />
          {{ calledOne.patient.device ? calledOne.patient.device : "-" }}°C
        </div>
      </div>
      <div class="panel_first2">
        <div class="d-flex justify-content-around">
          <div class="d-flex justify-content-center">
            <img
              v-if="!isIncendio || isSecondOption"
              class="img_panel_first2"
              :src="img"
              alt=""
            />
            <div class="content-fire" v-else>
              <div class="d-flex align-items-center">
                <div class="mr-3">
                  <img src="@/assets/images/fire.gif" alt="" />
                </div>
                <h1 class="title-fire">
                  PROTOCOLO DE <br />
                  EVACUACIÓN
                </h1>
              </div>
              <div class="mt-2">
                <div class="d-flex align-items-center content-protocolo">
                  <img src="@/assets/images/number1.gif" width="80" alt="" />
                  <h1>Conserve la calma</h1>
                </div>
                <div class="d-flex align-items-center content-protocolo">
                  <img src="@/assets/images/number2.gif" width="80" alt="" />
                  <h1>
                    Obedezca las indicaciones <br />
                    del personal
                  </h1>
                </div>
                <div class="d-flex align-items-center content-protocolo">
                  <img src="@/assets/images/number3.gif" width="80" alt="" />
                  <h1>No grite, no corra, no empuje</h1>
                </div>
                <div class="d-flex align-items-center content-protocolo">
                  <img src="@/assets/images/number4.gif" width="80" alt="" />
                  <h1>Cúbrase nariz y boca con un trapo</h1>
                </div>
                <div class="d-flex align-items-center content-protocolo">
                  <img src="@/assets/images/number5.gif" width="80" alt="" />
                  <h1>
                    Dirijanse al punto de encuentro <br />
                    y espere instrucciones
                  </h1>
                </div>
              </div>
            </div>
          </div>
          <div class="panel_right_first2">
            <h1 class="text_code2-1">{{ calledOne.patient.name }}</h1>
            <div class="text_bed2-1">{{ nameCalled }}</div>
            <div class="text-center mb-2">
              <Chronometer
                :timestamp="calledOne.timestamp"
                :mac="calledOne.mac"
                @deleteCall="deleteCall"
              />
            </div>
            <div v-if="!isIncendio && !isSecondOption">
              <div class="panel_bottom_first">
                <div class="box_panel_first_round2">
                  <div class="title_box_panel_first2 text-center">Ronda</div>
                  <div
                    class="d-flex justify-content-lg-center align-items-center"
                  >
                    <div class="round" />
                  </div>
                </div>
                <div class="box_panel_first">
                  <div class="title_box_panel_first2 text-center">Alimento</div>
                  <div v-if="calledOne.patient.orders.length > 0">
                    <div class="d-flex justify-content-center flex-column">
                      <div
                        v-for="(option, index) in calledOne.patient.orders"
                        :key="index"
                        :class="colorDiet(option.type, option.status)"
                        class="box_food"
                      >
                        {{ textDiet(option.type) }}
                      </div>
                    </div>
                  </div>
                  <div v-else class="text-center">
                    <b-badge variant="danger"> Sin asignar </b-badge>
                  </div>
                </div>
                <div class="box_panel_first_exam">
                  <div class="title_box_panel_first2 text-center">Exámen</div>
                  <div class="text-center">
                    <b-progress :max="100" animated variant="info">
                      <b-progress-bar
                        :value="
                          (calledOne.patient.exams.length /
                            calledOne.patient.examsTotal.length) *
                          100
                        "
                      >
                        <strong>{{
                          (calledOne.patient.exams.length /
                            calledOne.patient.examsTotal.length) *
                          100
                        }}</strong>
                      </b-progress-bar>
                    </b-progress>
                    <span class=""
                      ><strong
                        >{{ calledOne.patient.exams.length }} /
                        {{ calledOne.patient.examsTotal.length }}</strong
                      ></span
                    >
                  </div>
                </div>
                <div>
                  <div class="d-flex justify-content-end mb-2">
                    <img
                      class="img_indicador1"
                      :src="imgBateria(calledOne.bateria.value)"
                    />
                    <span class="text_bateria1">
                      {{
                        calledOne.bateria.value + " " + calledOne.bateria.unit
                      }}
                    </span>
                  </div>
                  <div
                    class="d-flex justify-content-end"
                    v-if="calledOne.timestamp"
                  >
                    <div>{{ calledOne.timestamp }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-card>

    <audio loop>
      <source preload="auto" :src="sound" type="audio/mpeg" />
      Your browser does not support the audio element.
    </audio>
  </div>
</template>

<script>
import {
  BCard,
  BBadge,
  BProgress,
  BProgressBar,
  VBTooltip,
} from "bootstrap-vue";
import Chronometer from "./Chronometer";

export default {
  components: {
    BCard,
    /* BBadge, */
    BProgress,
    BProgressBar,
    Chronometer,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  props: ["calledOne"],
  data() {
    return {
      isIncendio: false,
      img: null,
      nameCalled: null,
      exams: {
        chart: {
          type: "radialBar",
        },
        plotOptions: {
          radialBar: {
            hollow: {
              size: "40%",
            },
            track: {
              show: true,
              background: "#d2d2d2",
            },
            dataLabels: {
              show: false,
            },
          },
        },
        labels: [""],
        colors: ["#28c76f"],
      },
      medicines: {
        chart: {
          type: "radialBar",
        },
        plotOptions: {
          radialBar: {
            hollow: {
              size: "40%",
            },
            track: {
              show: true,
              background: "#d2d2d2",
            },
            dataLabels: {
              show: false,
            },
          },
        },
        labels: [""],
        colors: ["#35b0db"],
      },
      series: [50],
      sound: null,
      isSecondOption: false,
    };
  },
  methods: {
    shadowColor(name) {
      const me = this;
      let styleColor = "";

      if (name == "Paciente") {
        styleColor = "patient";
        me.isIncendio = false;
        me.isSecondOption = false;
        me.nameCalled = "PACIENTE";
        me.img = require("@/assets/images/llamadopaciente.gif");
        me.loadSound("sounPatient");
      } else if (name == "Incendio") {
        me.nameCalled = "INCENDIO";
        me.isIncendio = true;
        me.isSecondOption = false;
        styleColor = "orange";
        me.img = require("@/assets/images/incendiollamado.gif");
        me.loadSound("soundFire");
      } else if (name == "Rojo") {
        styleColor = "red";
        me.isIncendio = false;
        me.isSecondOption = false;
        me.nameCalled = "CÓDIGO ROJO";
        me.img = require("@/assets/images/codigorojo.gif");
        me.loadSound("soundRed");
      } else if (name == "Azul") {
        styleColor = "blue";
        me.isIncendio = false;
        me.isSecondOption = false;
        me.nameCalled = "CÓDIGO AZUL";
        me.img = require("@/assets/images/codigoazul.gif");
        me.loadSound("soundBlue");
      } else if (name == "Bano") {
        styleColor = "red";
        me.isIncendio = false;
        me.isSecondOption = true;
        me.nameCalled = "BAÑO";
        me.img = require("@/assets/images/llamadobano.gif");
        me.loadSound("soundBlue");
      } else if (name == "Vibracion") {
        styleColor = "blue";
        me.isIncendio = false;
        me.isSecondOption = true;
        me.nameCalled = "VIBRACIÓN";
        me.img = require("@/assets/images/camavibrando.gif");
        me.loadSound("soundBlue");
      }
      return styleColor;
    },
    imgBateria(value) {
      let img = null;

      if (value > 15) {
        img = require("@/assets/images/devices/bateriaconcarga.png");
      } else {
        img = require("@/assets/images/devices/bateriasincarga.png");
      }

      return img;
    },
    deleteCall(mac) {
      this.$emit("deleteCall", mac);
    },
    colorDiet(type, status) {
      let color = "";

      if (type == "1" && status) {
        color = "breakfast";
      } else if (type == "2" && status) {
        color = "luch";
      } else if (type == "3" && status) {
        color = "dinner";
      }
      return color;
    },
    textDiet(type) {
      let text = "";

      if (type == "1") {
        text = "Desayuno";
      } else if (type == "2") {
        text = "Almuerzo";
      } else {
        text = "Cena";
      }
      return text;
    },
    loadSound(name) {
      const me = this;
      const audio = document.querySelector("audio");

      if (audio) {
        if (localStorage.getItem(name)) {
          me.sound = JSON.parse(localStorage.getItem(name)).audio;
        } else {
          me.sound = require("@/assets/audio/alerta.mp3");
        }

        audio.load();
        // Show loading animation.
        let playPromise = audio.play();

        if (playPromise !== undefined) {
          playPromise
            .then((_) => {
              // Automatic playback started!
              // Show playing UI.
            })
            .catch((error) => {
              // Auto-play was prevented
              // Show paused UI.
            });
        }
      }
    },
  },
  mounted() {},
};
</script>

<style lang="css">
:root {
  --d: 4s;
  --angle: 90deg;
  --gradX: 100%;
  --gradY: 50%;
  --c2: rgba(7, 1, 1, 0.1);
  --red: rgba(234, 84, 85, 1);
  --patient: rgba(0, 204, 153, 1);
  --orange: rgba(225, 149, 99, 1);
  --blue: rgba(41, 162, 204, 1);
}

.card_first2 {
  box-shadow: 0 4px 15px 0 white;
  background-color: white;
  border: 0.35rem solid;
  height: calc(100vh - 100px);
}

@property --angle {
  syntax: "<angle>";
  initial-value: 90deg;
  inherits: true;
}

@property --gradX {
  syntax: "<percentage>";
  initial-value: 50%;
  inherits: true;
}

@property --gradY {
  syntax: "<percentage>";
  initial-value: 0%;
  inherits: true;
}

.card_first2.red {
  /* box-shadow: 0 4px 15px 10px rgba(234, 84, 85, 1); */
  border-image: conic-gradient(
      from var(--angle),
      var(--c2),
      var(--red) 0.1turn,
      var(--red) 0.15turn,
      var(--c2) 0.25turn
    )
    30;
  animation: borderRotate var(--d) linear infinite forwards;
}

.card_first2.patient {
  /* box-shadow: 0 4px 15px 10px rgba(0, 204, 153, 1); */
  border-image: conic-gradient(
      from var(--angle),
      var(--c2),
      var(--patient) 0.1turn,
      var(--patient) 0.15turn,
      var(--c2) 0.25turn
    )
    30;
  animation: borderRotate var(--d) linear infinite forwards;
}

.card_first2.orange {
  /* box-shadow: 0 4px 15px 10px rgba(225, 149, 99, 1); */
  border-image: conic-gradient(
      from var(--angle),
      var(--c2),
      var(--orange) 0.1turn,
      var(--orange) 0.15turn,
      var(--c2) 0.25turn
    )
    30;
  animation: borderRotate var(--d) linear infinite forwards;
}

.card_first2.blue {
  /* box-shadow: 0 4px 15px 10px rgba(41, 162, 204, 1); */
  border-image: conic-gradient(
      from var(--angle),
      var(--c2),
      var(--blue) 0.1turn,
      var(--blue) 0.15turn,
      var(--c2) 0.25turn
    )
    30;
  animation: borderRotate var(--d) linear infinite forwards;
}

@keyframes borderRotate {
  100% {
    --angle: 420deg;
  }
}

@keyframes borderRadial {
  20% {
    --gradX: 100%;
    --gradY: 50%;
  }
  40% {
    --gradX: 100%;
    --gradY: 100%;
  }
  60% {
    --gradX: 50%;
    --gradY: 100%;
  }
  80% {
    --gradX: 0%;
    --gradY: 50%;
  }
  100% {
    --gradX: 50%;
    --gradY: 0%;
  }
}

.panel_fake {
  height: 425px;
}

.header_first2 {
  background-color: white;
  height: 85px;
}

.name_header2 {
  font-size: 22px;
  color: #5b5b5b;
  font-family: var(--fuente-montserrat);
}

.title_riesk_first2 {
  font-size: 16px;
  color: #5b5b5b;
  font-family: var(--fuente-montserrat);
  position: relative;
  top: -5px;
}

.bar_rish_riesk_first2 {
  padding: 3px;
  border-radius: 50px;
  background-color: #7367f0;
  margin-right: 5px;
  width: 40px;
  position: relative;
  top: -10;
}

.title_icon_temp2 {
  font-size: 32px;
  color: #5b5b5b;
  font-family: var(--fuente-montserrat);
}

.icon_temperature2 {
  position: relative;
  left: -5px;
  color: #5b5b5b;
  height: 40px;
}

.panel_first2 {
}

.panel_bottom_first {
  display: flex;
  justify-content: space-between;
}

.box_panel_first2 {
  /* height: 95px; */
}

.box_panel_first_round2 {
  /* height: 70px; */
}

.box_panel_first_exam {
  /* height: 80px; */
  margin-bottom: 5px;
}

.title_box_panel_first2 {
  font-size: 18px;
  color: #5b5b5b;
  font-family: var(--fuente-montserrat);
  margin-bottom: 5px;
}

.round {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #b2b2b2;
}

#radiar_bar_first {
  display: flex;
  justify-content: center;
  position: relative;
  top: -25px;
  margin-bottom: -60px;
  margin-right: -40px;
  margin-left: -40px;
}

.title_radiar1_first {
  position: relative;
  top: -35px !important;
  color: #28c76f;
  font-weight: 700;
  font-size: 16px;
}

.title_radiar2_first {
  position: relative;
  top: -35px !important;
  color: #35b0db;
  font-weight: 700;
  font-size: 16px;
}

.box_food2 {
  background: #b2b2b2;
  width: 90%;
  height: 20px;
  margin-bottom: 2px;
  font-size: 14px;
  color: white;
  border-radius: 0.2rem;
  text-align: center;
}

.box_food2.active {
  background-color: #28c76f;
}

.img_panel_first2 {
  height: auto;
  position: relative;
  top: 0px;
}

.panel_right_first2 {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: left;
}

.text_code2-1 {
  font-size: 68px;
  color: #5b5b5b;
  font-family: var(--fuente-montserrat);
  font-weight: bold;
}

.text_bed2-1 {
  font-size: 38px;
  color: #5b5b5b;
  letter-spacing: 1px;
  font-family: var(--fuente-montserrat);
  position: relative;
  top: -15px;
}

#radiarBar_time2 {
  position: relative;
  top: -50px;
  margin-bottom: -60px;
  margin-right: -40px;
  margin-left: -40px;
}

.time2 {
  position: relative;
  top: -109px;
}

.time_2 {
  font-size: 20px;
  color: #5b5b5b;
  font-family: var(--fuente-montserrat);
  font-weight: 700;
}

.text_time2 {
  font-size: 12px;
  color: #5b5b5b;
  font-family: var(--fuente-montserrat);
  font-weight: 700;
}

.text_bateria1 {
  font-size: 16px;
  color: var(--black-color);
  font-weight: bold;
  margin-left: 5px;
  position: relative;
  top: 5px;
}

.img_indicador1 {
  width: 50px;
  height: 20px;
  position: relative;
  top: 5px;
}

.content-top-1 {
  position: relative;
  top: -70px;
}
.title-fire {
  font-size: 40px;
  text-align: center;
  font-weight: 700;
  color: #e73e3e;
  margin-bottom: 0 !important;
}
.content-protocolo h1 {
  font-size: 20px;
  text-align: left !important;
  font-weight: 900;
  color: #e73e3e;
  margin-bottom: 0 !important;
  text-transform: uppercase;
}
</style>

<template>
  <div>
    <apexchart
      type="radialBar"
      id="radiarBar_time2"
      height="330"
      :options="chartOptions"
      :series="series2"
    ></apexchart>
    <div class="time2">
      <div class="time_2">{{ minutes }} : {{ seconds }}</div>
      <div class="text_time2">Tiempo transcurrido</div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  props: ['timestamp', 'mac'],
  data() {
    return {
      chartOptions: {
        plotOptions: {
          radialBar: {
            size: 150,
            offsetY: 20,
            startAngle: -150,
            endAngle: 150,
            hollow: {
              size: "65%",
            },
            track: {
              background: "#fff",
              strokeWidth: "100%",
            },
            dataLabels: {
              show: false,
            },
          },
        },
        colors: ["#ea5455"],
        fill: {
          type: "gradient",
          gradient: {
            shade: "dark",
            type: "horizontal",
            shadeIntensity: 0.5,
            gradientToColors: ["#28c76f"],
            inverseColors: true,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100],
          },
        },
        stroke: {
          dashArray: 8,
        },
        labels: [""],
      },
      series2: [0],
      seconds : 0,
      seconds2: 0,
      minutes: 0,
      serie: 0,
      interval: null,
    }
  },
  watch: {
    timestamp(newTime) {
      const me = this;
      me.calculateElapsedMinutes(newTime);
    }
  },
  methods: {
    chronometer() {
      const me = this;
      me.interval = setInterval(() => {
        me.counter();
      }, 1000);
    },
    counter() {
      const me = this;
      me.seconds++;
      me.seconds2++;
      
      if (me.seconds2 <= 300) {
        me.serie+= 0.33
        me.series2 = [me.serie]
      }

      if (me.seconds < 10) me.seconds = `0${me.seconds}`;
      if (me.seconds == 60) {
        me.minutes++;
        me.seconds = 0;

        if (me.minutes < 10) me.minutes = `0${me.minutes}`;

        if (me.minutes == 5) {
          me.series2 = [0]
          me.minutes = 0;
          me.seconds = `0${me.seconds}`;
          me.seconds2 = 0;
          clearInterval(me.interval);
          me.$emit("deleteCall", me.mac);
        }
      }
    },
    calculateElapsedMinutes(timestamp) {
      const me = this;
      clearInterval(me.interval);
      me.series2 = [0];
      me.seconds  = 0;
      me.seconds2 = 0;
      me.minutes = 0;
      me.serie = 0;
      // const timestamp = "2022-01-29 16:00:00"
      const timeHour = timestamp.split(" ");
      const minuteSecond = timeHour[1].split(":");
      const time = `${minuteSecond[1]}:${minuteSecond[2]}`;

      const currentHour = moment().format('mm:ss');
      const diff = moment(currentHour,"mm:ss").diff(moment(time,"mm:ss"));
      const duration = moment.utc(diff).format("mm:ss");

      const minutes = duration.split(":")[0];
      const seconds = duration.split(":")[1];
      me.minutes = minutes;
      me.seconds = seconds;
      
      const sec = Number(me.minutes * 60) + Number(me.seconds)
      me.serie = sec * 0.33;
      me.series2 = [me.serie];
      me.chronometer();
    }
  },
  mounted() {
    const me = this;
    me.calculateElapsedMinutes(me.timestamp);
  }
};
</script>

<style></style>

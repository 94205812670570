<template>
  <div>
    <div class="mt-3 pl-3 pr-1 top__">
      <b-row>
        <b-col sm="9">
          <card-first @deleteCall="deleteCall" />
        </b-col>
        <b-col sm="3" class="pl-0">
          <card-waiting @deleteCall="deleteCall" />
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { BRow, BCol } from "bootstrap-vue";
import CardFirst from "./CardFirst";
import CardWaiting from "./CardWaiting";

export default {
  components: {
    BRow,
    BCol,
    CardFirst,
    CardWaiting,
  },
  props: {
    calls: {
      type: Array,
      default: () => [],
    },
    // areaId: {
    //   type: String,
    //   default: null,
    // },
  },
  data() {
    return {};
  },
  methods: {
    deleteCall(mac, sensor) {
      this.$emit('deleteCall', mac, sensor);
    }
  },
  // created() {
  //   const me = this;
  //   window.Echo.channel(`menu-order-${me.areaId}`).listen(
  //     ".menu.order.created",
  //     (e) => {
  //       const order = JSON.parse(e.data);
  //       me.$emit("fillDiet", order);
  //     }
  //   );
  //   window.Echo.channel(`exam-patient-${me.areaId}`).listen(
  //     ".exam.patient.created",
  //     (e) => {
  //       const exam = JSON.parse(e.data);
  //       me.$emit("fillExam", exam);
  //     }
  //   );
  // },
  mounted() {},
};
</script>

<style lang="css">
  .top__ {
    position: relative;
    top: -10px;
  }
</style>

<template>
  <div>
    <div class="box_waiting2 mb-3 d-flex align-items-center">
      <span class="title_box_waiting2"> Llamados en espera </span>
      <div class="box_circle">
        {{ allCalls.length }}
      </div>
    </div>
    <div class="mb-2" v-for="(item, index) in allCalls" :key="index">
      <div class="header__waiting2" :class="shadowColor(item.name)">
        <div class="d-flex justify-content-between">
          <div class="title_header_waiting2">{{ item.patient.name }}</div>
          <time-chronometer
            :timestamp="item.timestamp"
            :mac="item.mac"
            :item="item"
            @deleteCall="deleteCall"
          />
        </div>
        <div class="d-flex justify-content-between align-items-center">
          <div>
            <div class="title_riesk_waiting2">Riesgos</div>
            <div class="d-flex">
              <div
                v-for="risk in item.patient.location_patient.risks.slice(0, 3)"
                :key="risk.id"
                v-b-tooltip.hover.top="risk.name"
                class="bar_riesk_waiting2"
                :style="'background-color: ' + risk.color"
              />
            </div>
          </div>
          <div class="temp__waiting">
            <img
              src="@/assets/images/icontemperatura.png"
              class="icon_temperature_waiting2"
            />
            <span class="title_icon_temp_waiting">24ºC</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { VBTooltip } from "bootstrap-vue";
import TimeChronometer from "./TimeChronometer";
import { mapState } from "vuex";

export default {
  components: {
    TimeChronometer,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {};
  },
  computed: {
    ...mapState("patient", ["allCalls"]),
  },
  methods: {
    shadowColor(name) {
      let styleColor = "";
      // const name = item[0].name;

      if (name == "Paciente") {
        styleColor = "patient";
      } else if (name == "Incendio") {
        styleColor = "orange";
      } else if (name == "Rojo") {
        styleColor = "red";
      } else if (name == "Bano") {
        styleColor = "red";
      } else {
        styleColor = "blue";
      }
      return styleColor;
    },
    deleteCall(mac, sensor) {
      const me = this;
      me.$emit("deleteCall", mac, sensor);
    },
  },
};
</script>

<style lang="css">
:root {
  --d: 4s;
  --angle: 90deg;
  --gradX: 100%;
  --gradY: 50%;
  --c2: rgba(7, 1, 1, 0.1);
  --red: rgba(234, 84, 85, 1);
  --patient: rgba(0, 204, 153, 1);
  --orange: rgba(225, 149, 99, 1);
  --blue: rgba(41, 162, 204, 1);
}

@property --angle {
  syntax: "<angle>";
  initial-value: 90deg;
  inherits: true;
}

@property --gradX {
  syntax: "<percentage>";
  initial-value: 50%;
  inherits: true;
}

@property --gradY {
  syntax: "<percentage>";
  initial-value: 0%;
  inherits: true;
}

.header__waiting2 {
  background-color: var(--white-color) !important;
  /* border-radius: .3rem; */
  padding: 5px;
  height: 65px;
  box-shadow: 0 4px 15px 0 white;
  border: 0.2rem solid !important;
}

.header__waiting2.red {
  /* box-shadow: 0 4px 15px 10px rgba(234, 84, 85, 1); */
  border-image: conic-gradient(
      from var(--angle),
      var(--c2),
      var(--red) 0.1turn,
      var(--red) 0.15turn,
      var(--c2) 0.25turn
    )
    5 !important;
  animation: borderRotate var(--d) linear infinite forwards;
}

.header__waiting2.patient {
  /* box-shadow: 0 4px 15px 10px rgba(0, 204, 153, 1); */
  border-image: conic-gradient(
      from var(--angle),
      var(--c2),
      var(--patient) 0.1turn,
      var(--patient) 0.15turn,
      var(--c2) 0.25turn
    )
    5 !important;
  animation: borderRotate var(--d) linear infinite forwards;
}

.header__waiting2.orange {
  /* box-shadow: 0 4px 15px 10px rgba(225, 149, 99, 1); */
  border-image: conic-gradient(
      from var(--angle),
      var(--c2),
      var(--orange) 0.1turn,
      var(--orange) 0.15turn,
      var(--c2) 0.25turn
    )
    5 !important;
  animation: borderRotate var(--d) linear infinite forwards;
}

.header__waiting2.blue {
  /* box-shadow: 0 4px 15px 10px rgba(41, 162, 204, 1); */
  border-image: conic-gradient(
      from var(--angle),
      var(--c2),
      var(--blue) 0.1turn,
      var(--blue) 0.15turn,
      var(--c2) 0.25turn
    )
    5 !important;
  animation: borderRotate var(--d) linear infinite forwards;
}

@keyframes borderRotate {
  100% {
    --angle: 420deg;
  }
}

@keyframes borderRadial {
  20% {
    --gradX: 100%;
    --gradY: 50%;
  }
  40% {
    --gradX: 100%;
    --gradY: 100%;
  }
  60% {
    --gradX: 50%;
    --gradY: 100%;
  }
  80% {
    --gradX: 0%;
    --gradY: 50%;
  }
  100% {
    --gradX: 50%;
    --gradY: 0%;
  }
}

.icon_temperature_waiting2 {
  position: relative;
  left: -2px;
  height: 25px;
}

.padding__waiting {
  padding: 5px;
}

.title_header_waiting2 {
  font-size: 16px;
  color: #5b5b5b;
  font-weight: 700;
  font-family: var(--fuente-montserrat);
}

.title_riesk_waiting2 {
  font-size: 12px;
  color: #5b5b5b;
  font-weight: 700;
  font-family: var(--fuente-montserrat);
  position: relative;
  top: -5px;
}

.bar_riesk_waiting2 {
  padding: 4px;
  border-radius: 0.2rem;
  background-color: #5b5b5b;
  margin-right: 5px;
  width: 25px;
  position: relative;
  top: -5px;
}

.box_waiting2 {
  padding: 5px;
  border-radius: 0.3rem;
  background-color: #eaeaea;
  box-shadow: 0 5px 11px 0 rgba(145, 144, 144, 0.3),
    0 4px 15px 0 rgba(133, 132, 132, 0.15);
}

.title_box_waiting2 {
  font-size: 12px;
  font-weight: 900;
  margin-right: 5px;
  font-family: var(--fuente-montserrat);
}

.box_circle {
  background-color: #5b5b5b;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  color: white;
  font-size: 16px;
  font-family: var(--fuente-montserrat);
  text-align: center;
}

.title_icon_temp_waiting {
  font-size: 18px;
  color: #5b5b5b;
  font-family: var(--fuente-montserrat);
}

.btn_icon {
  padding: 5px;
  font-size: 14px;
  background-color: #5b5b5b;
  color: var(--black-color);
}

.header__waiting.blue {
  background-color: #256eff;
}

.header__waiting.red {
  background-color: #e2252b;
}

.header__waiting.orange {
  background-color: #fe621d;
}

.header__waiting.green {
  background-color: #088044;
}

.time_chronometer {
  font-size: 16px;
  color: #5b5b5b;
  font-family: var(--fuente-montserrat);
}

.temp__waiting {
  position: relative;
  top: -5px;
}
</style>
